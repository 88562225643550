import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Section,
  BreadCrumb,
  Background,
  Flex,
  FlexTwoCol,
  MarkdownContent,
  SectionPageTitle,
  Container,
  SectionTitle,
} from "../components/SectionTags"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import ItemHover from "../components/ItemHover"
import AddressIcon from "../components/Icons/AddressIcon"
import ClockIcon from "../components/Icons/ClockIcon"
import ArrowIcon from "../components/Icons/ArrowIcon"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import BreakpointUp from "../components/Media/BreakpointUp"

const SectionSubTitle = styled.div`
  span, a{
    display:inline-block;
    vertical-align:middle;
  }
  p{
    margin-bottom:0;
  }
  > a{
    padding: 0 5px;
    color:#0059B2;
    &:hover, &:focus{
      color:#fab702;
    }
  }
`
const JobGrid = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 30px;
  grid-template-columns: repeat(1, 1fr);
  padding-top: 0px;
  ${BreakpointUp.md`
		grid-template-columns: repeat(2, 1fr);
		padding-top:20px;
	`}
  ${BreakpointUp.xl`
		grid-template-columns: repeat(3, 1fr);
	`}
`
const JobCard = styled.div`
  box-shadow: 0 15px 35px rgba(0, 89, 178, 0.1);
  border: 1px solid #d6ecff;
  border-radius: 8px;
  background: ${(props) => props.bg};
  cursor: pointer;
  height:100%;
  & h3 {
    display: block;
    color: ${(props) => props.hColor};    
    margin-bottom: 10px;
    @media (min-width: 992px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
  & p {
    color: ${(props) => props.pColor};
    font-size: 18px;
    line-height: 32px;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    .icon {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      + .text {
        margin-left: 15px;
      }
      & svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  & .posted {
    margin-top: 15px;
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    display: block;
    color: ${(props) => props.pColor};
  }
`
const JobCardBody = styled.div`
  padding: 25px;
  ${BreakpointUp.sm`
		padding: 50px 30px 25px;
	`}
`
const JobCardFooter = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  ${BreakpointUp.sm`
		padding: 25px 30px 25px;
	`}
  &:before {
    background-color: #d6ecff;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 1px;
    width: 90%;
  }
  & .text {
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 800;
    font-size: 18px;
    line-height: 30px;
    font-weight: 700;
    color: #fab702;
  }
  & .icon {
    width: 20px;
  }
`
class JoinOurTeam extends React.Component {
  render() {
    const pageData = this.props.data.contentfulJoinOurTeamPage
    console.log(pageData,"pageData")
    const allJobs = this.props.data.allContentfulJob
    return (
      <Layout>
        <Seo title="Join our Team at AES  - We are hiring!" description="Join AES Team- Find the career you deserve! AES offers a great work and team environment, professional development, challenging careers, exceptional benefits, and competitive compensation" />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Section bgColor="#EDF8FF" xpt="120px" xpb="80px" mpt="120px" mpb="60px" pt="90px" pb="30px">
          <Background width="100%" maxHeight="auto">
            <GatsbyImage image={getImage(pageData.heroBackground)} alt="AES" />
          </Background>
          <Container>
            <BreadCrumb>
              <Link to="/">
                <span>Home</span>
              </Link>
              Join Our Team
            </BreadCrumb>
            <Flex alignItem="center" justifyContent="space-between">
              <FlexTwoCol flexWidth="66.666667%" flexMWidth="66.666667%">
                <SectionPageTitle>{pageData.title}</SectionPageTitle>               
                <MarkdownContent
                  dangerouslySetInnerHTML={{
                    __html: pageData.description.childMarkdownRemark.html,
                  }}
                />
              </FlexTwoCol>
            </Flex>
          </Container>
        </Section>

        <Section xpt="80px" xpb="80px" mpt="60px" mpb="60px" pt="40px" pb="40px">
          <Container>
            <SectionTitle mb="0">{pageData.jobsSectionTitle}</SectionTitle>
            {/* <SectionSubTitle className="h5">Check out our open positions below! To apply, click <a href="https://recruiting.paylocity.com/recruiting/jobs/All/da9c5e97-8c64-49a3-aaac-4d89309ecf1f/Advanced-Electronic-Services-Inc" target="_blank">Apply Now</a></SectionSubTitle> */}
            <SectionSubTitle className="h5">
              <span
              dangerouslySetInnerHTML={{
                __html: pageData.descriptionAboutJobs.childMarkdownRemark.html,
              }}
            />
              <a href="https://recruiting.paylocity.com/recruiting/jobs/All/da9c5e97-8c64-49a3-aaac-4d89309ecf1f/Advanced-Electronic-Services-Inc" target="_blank" rel="noreferrer">Apply Now</a>
            </SectionSubTitle>
          
            
          </Container>
          <Container>
            <JobGrid>
              {allJobs.edges.map((item, i) => {
                return (
                  <ItemHover>
                    {(hoverItem) => (
                      <Link to={`/job/${item.node.url}`}>
                        <JobCard
                          key={i}
                          bg={hoverItem ? "#0059B2" : "none"}
                          hColor={hoverItem ? "#FFF" : "#000"}
                          pColor={
                            hoverItem ? "rgba(255, 255, 255, 0.8)" : "#9B9EA2"
                          }
                        >
                          <JobCardBody>
                            <h3>{item.node.title} </h3>
                            <p>
                              <span className="icon">
                                <AddressIcon
                                  fill={hoverItem ? "#FFF" : "#9B9EA2"}
                                />
                              </span>
                              <span className="text">{item.node.location}</span>
                            </p>
                            <p>
                              <span className="icon">
                                <ClockIcon
                                  fill={hoverItem ? "#FFF" : "#9B9EA2"}
                                />
                              </span>
                              <span className="text">{item.node.jobType}</span>
                            </p>
                            <span className="posted">
                              Updated {item.node.updatedAt}
                            </span>
                          </JobCardBody>
                          <JobCardFooter>
                            <span className="text"> Apply Now </span>
                            <span className="icon">
                              <ArrowIcon fill="#FAB702" />
                            </span>
                          </JobCardFooter>
                        </JobCard>
                      </Link>
                    )}
                  </ItemHover>
                )
              })}
            </JobGrid>
          </Container>
        </Section>
        <EmergencyEstimateCTA
          leftData={pageData.emergencyCta}
          rightData={pageData.estimateCta}
        />
      </Layout>
    )
  }
}

export default JoinOurTeam

export const pageQuery = graphql`
  query JoinOurTeamPageQuery {
    contentfulJoinOurTeamPage {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      heroBackground {
        gatsbyImageData(quality: 100)
      }
      jobsSectionTitle
      descriptionAboutJobs {
        childMarkdownRemark {
          html
        }
      }
      emergencyCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      estimateCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
    }
    allContentfulJob {
      edges {
        node {
          url
          title
          location
          jobType
          twitter
          jobDetails {
            childMarkdownRemark {
              html
            }
          }
          createdAt(fromNow: true)
          updatedAt(fromNow: true)
        }
      }
    }
  }
`
